import React from 'react';
import {graphql, StaticQuery, Link} from 'gatsby';
import Logo from '../assets/logo.svg';

class MainMenuMRP extends React.Component {
constructor(props){  
    super(props);  
    this.state = {  
        mobileMenuOpen: false   
    }  
    this.clickToggleMenu = this.clickToggleMenu.bind(this);  
} 

clickToggleMenu(){  
    this.setState({
     mobileMenuOpen: !this.state.mobileMenuOpen
    });

    const bodyTag = document.body;
     if (bodyTag.classList.contains('lockup')) {
         bodyTag.classList.remove('lockup');
         document.documentElement.classList.remove('lockup');
     } else{
       bodyTag.classList.add('lockup');
       document.documentElement.classList.add('lockup');
     }
 }  

render(){
    return(
        <StaticQuery query={graphql`
    {
        allMainMenuMrpJson {
          edges {
            node{
              ID
                title
                url
                classes
            }
          }
        }
      }
    `} render={props =>(
        <header className={`site-header${(this.props.rcStyle === 'all-grey') ? ' not-blue' : ''} 
          ${this.state.mobileMenuOpen  ? ' mobile-open' : ''}`}
          >
           <Link className="logo-link" to="/" aria-label="link to homepage"  >
            <Logo className="header-logo" />
          </Link>
        
          <div className="menu-wrap">
              {props.allMainMenuMrpJson.edges.map(item => (
                <Link 
                  exact="true"        
                  to={item.node.url} 
                  key={item.node.ID} 
                  activeClassName="active"
                 // partiallyActive={true}
                  className={`${item.node.classes}`} 
                    
                  >
                      {item.node.title}
                  </Link> 
              ))}
          </div>
          <button className="mobile-menu-trigger" onClick={this.clickToggleMenu}>
                <span className="line top-line"></span>
                <span className="line bottom-line"></span>
                <span className="sr-only">Toggle Menu</span>
          </button>
        </header>
    )} />
    )
}
}

export default MainMenuMRP;
