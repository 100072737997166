import React from "react"
import ScrollUpArrow from '../assets/scroll-up-arrow.svg';

const ScrollToTop = () => {
    return(
        <button id="scroll-btn" className="sidebar-btns" title="Scroll to top">
            <ScrollUpArrow />
        </button>
    )
}

export default ScrollToTop;