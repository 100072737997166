import React from 'react';
import {graphql, StaticQuery, Link} from 'gatsby';
import FooterLogo from './FooterLogo';
export default function SiteFooter({data}) {
    return(
      <StaticQuery query={graphql`
    {
        allFooterMenuJson {
          edges {
            node{
              ID
              title
              url
              classes
            }
          }
        }
      }
    `} render={props =>(
        <footer className="site-footer">
            <div className="footer-left-column">
                <Link to="/">
                  <picture>
                    <FooterLogo />
                  </picture>
                </Link>
                <p className="copyright-wrap">&copy; {(new Date().getFullYear())} Mars, Incorporated and its Affiliates. All Rights Reserved.</p>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
            </div>
          <div className="menu-wrap">
              {props.allFooterMenuJson.edges.map(item => (
                <Link 
                  to={item.node.url} 
                  key={item.node.ID} 
                  activeClassName={`${item.node.url !== '/' ? 'active' : 'homepage-fooer-link'}`}  
                  className={`${item.node.classes}`} 
                  partiallyActive={true}   
                  >
                      {item.node.title}
                  </Link> 
              ))}
          </div>
        </footer>
    )} />
    )

}
