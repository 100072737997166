import React from 'react';
import MainMenu from './MainMenu';
import MainMenuARP from './MainMenuARP';
import MainMenuMRP from './MainMenuMRP';
import MainMenuWRP from './MainMenuWRP';
import MainMenuNutro from './MainMenuNutro';

class SiteHeader extends React.Component {

  componentDidMount() {
    const bodyTag = document.body;
    bodyTag.classList.remove('lockup');
    document.documentElement.classList.remove('lockup');


  //Add active class to main menu item if the child item is in the left hand navigation
  let activeSideItem = document.querySelector('.side-menu-link.active');
  let activeMainFromSide = document.querySelector('.active-when-side-active');
  let dontActiveAWA = document.querySelector('.dont-activate-awa.active');
  if(activeSideItem && activeMainFromSide && !dontActiveAWA){
    activeMainFromSide.className += ' active';
  }

  //Remove tooltips from elements with "no-tooltip" class
  const ttelems = document.querySelectorAll('.no-tooltip .tooltip-item');

  if(ttelems){
    ttelems.forEach(ttelem => {
      ttelem.parentNode.removeChild(ttelem);
    });
  }
  
  //Remove tooltips inside nested glossary terms
  const nestedTooltips = document.querySelectorAll('.glossary-term .glossary-term .tooltip-item');

    if(nestedTooltips){
      nestedTooltips.forEach(ntt => {
        ntt.parentNode.removeChild(ntt);
      });
    }
    
  //Remove tooltips inside of tooltips
  const metaTooltips = document.querySelectorAll('.glossary-term .tooltip-item .tooltip-item');
    if(metaTooltips){
      metaTooltips.forEach(mtt => {
        mtt.parentNode.removeChild(mtt);
      });
    }
  }

  render(){
    return(
      <div>
      { //conditional main menu rendered depending on page slug or parent slug
          (this.props.slug === 'usrp-arp' || this.props.parent === 'usrp-arp') ?
           <MainMenuARP rcStyle={this.props.rcStyle} />: 
            (this.props.slug === 'usrp-mrp' || this.props.parent === 'usrp-mrp') ? 
              <MainMenuMRP rcStyle={this.props.rcStyle} /> :
              (this.props.slug === 'usrp-wrp' || this.props.parent === 'usrp-wrp') ? 
                <MainMenuWRP rcStyle={this.props.rcStyle} /> :
                (this.props.slug === 'nutro' || this.props.parent === 'nutro') ? 
                  <MainMenuNutro rcStyle={this.props.rcStyle} /> :
                  <MainMenu rcStyle={this.props.rcStyle} />
        }

        </div>
      
    )
  }

}

export default SiteHeader;
